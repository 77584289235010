import React from 'react'
import Layout from '~/components/layout'
import SEO from '~/components/seo'
import { graphql } from 'gatsby'

const Newsletter = ({ data: { allStrapiPageNewsletter } }) => {
  const newsletterData = allStrapiPageNewsletter.nodes[0]
  const seo = {
    title: 'Newsletter',
    metaDescription:
      'Inscrivez-vous à notre newsletter afin de rester informé de nos prochaines actualités.',
  }
  return (
    <Layout>
      <SEO seo={seo} />
      <div
        className="bg-no-repeat bg-cover h-full"
        style={{ backgroundImage: `url(${newsletterData.background.url})` }}
      >
        <div className="grid grid-cols-12 container pb-8">
          <div className="col-start-1 col-end-13 min-h-screen">
            <iframe
              width="540"
              height="600"
              src="https://144c2771.sibforms.com/serve/MUIEADP3WOIPrtkaTdYglADHEpQ-_WJbHV30XA1vGdvq495swsDaBmAipcqOeEjmWO5U5j7IipofcI_vd7b5PbvT3VVxpYjpfPDZBjv6E8jZit9xL43fljHfpdPn4Hy05oD0Ad51YkG6_-xJpzwDG7fYe9aO6kN1TNB0YN9C9jjJTCnlaOh-DT_o_DBTFGilpLT3HqGjCw9a0a0m"
              className="push-content"
              frameBorder="0"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '100%',
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query newsletterQuery {
    allStrapiPageNewsletter {
      nodes {
        background {
          url
        }
      }
    }
  }
`

export default Newsletter
